import React, { useState,useEffect } from 'react';

import SelectTechInput from '../containers/SelectTechInput';
import AddTroopsInput from '../containers/AddTroopsInput';
import CurrentUnitList from '../containers/CurrentUnitList';

import shipData from '../data/shipProfiles.json';

import capitalShips from '../data/capitalShipProfiles.json';

import styles from '../inputBox.module.css';

import WinChart from '../graphs/WinChart';
import SurvivorChart from '../graphs/SurvivorChart';
import IpcChart from '../graphs/IpcChart';

import UtilityBtns from '../components/UtilityBtns';
import OptionsDropdown from '../components/TuneSettings';

import { TitleBar } from '../components/TitleBar';
import { SearchModal } from '../components/SearchModal';
import { SaveModal } from '../components/SaveModal';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

function SeaPage({ baseURL }) {
    const seaTech = {
        "Super Subs": "radioBtn0",
        "Super Battleships": "radioBtn1",
        "Super Carriers": "radioBtn2", 
        "Improved Transports": "radioBtn3",
        "Jet Fighters": "radioBtn4",
        "Heavy Bombers": "radioBtn5",
        "No Tech": "radioBtnX"
    };

    const techDict = {
        "radioBtn0": {"efx": "Submarine", 0: 3, 1: 1},
        "radioBtn3": {"efx": "Transport", 0: 0, 1: 1},
        "radioBtn4": {"efx": "Fighter", 0: 4, 1: 4},
        "radioBtn5": {"efx": "Strategic Bomber", 0: [3,3], 1: null},
        "radioBtnX": {"efx": null},
        "capitalTechs" : {
            "radioBtn1": {
                "Super Battleship": {0: [4,2], 1: [4,2], "hp": ["f", "f", "f"]},
                "Hurt Super Battleship": {0: [4,2], 1: [4,2], "hp": ["f", "f", "x"]},
                "Damaged Super Battleship":  {0: [2,1], 1: [2,1], "hp": ["f", "x", "x"]}
            },
            "radioBtn2": {
                "Super ACC": {0: 0, 1: 2, "hp": ["f", "f", "f"]},
                "Hurt Super ACC": {0: 0, 1: 2, "hp": ["f", "f", "x"]},
                "Damaged Super ACC": {0: 0, 1: 1, "hp": ["f", "x", "x"]}
            }
        }
    }
    const [attackAvailable, setAttackAvailable] = useState(Object.keys(shipData).filter((key) => key !== "Transport"));
    const [defenseAvailable, setDefenseAvailable] = useState(Object.keys(shipData).filter((key) => key !== "Strategic Bomber"));

    const [attackUnits, setAttackUnits] = useState([]);
    const [defenseUnits, setDefenseUnits] = useState([]);

    const [singleRound, setSingleRound] = useState(false);
    const [showOptions, setShowOptions] = useState(false);
    const [allowUnitListRender, setAllowUnitListRender] = useState(true);

    const baseBSs = ["Battleship", "Damaged Battleship"];
    const baseACCs = ["ACC", "Damaged ACC"];
    const superBSs = ["Super Battleship", "Hurt Super Battleship", "Damaged Super Battleship"];
    const superACCs = ["Super ACC", "Hurt Super ACC", "Damaged Super ACC"];

    const [avalAtkCapitalShips, setAttackerCapitalShips] = useState([baseACCs, baseBSs]);
    const [avalDefCapitalShips, setDefenderCapitalShips] = useState([baseACCs, baseBSs]);

    const handleAddUnit =  ({ target }) => {
        let ship_to_add = {
            "name" : target.innerText,
            "count" : 1,
        }

        if (target.innerText in capitalShips) {
            ship_to_add["hp"] = capitalShips[target.innerText]["hp"]
            if (target.dataset.side === "0") {
                ship_to_add["val"] = capitalShips[target.innerText]['a']
                setAttackerCapitalShips((prevAval) => [prevAval[0].filter(ship => ship !== target.innerText), 
                                                        prevAval[1].filter(ship => ship !== target.innerText)]);
            } else {
                ship_to_add["val"] = capitalShips[target.innerText]['d']
                setDefenderCapitalShips((prevAval) => [prevAval[0].filter(ship => ship !== target.innerText),
                                                        prevAval[1].filter(ship => ship !== target.innerText)]);
            }
        } else {
            if (target.dataset.side === "0") {
                ship_to_add["val"] = shipData[target.innerText]['a']
                setAttackAvailable((prevAtkAval) => prevAtkAval.filter(unit => unit !== target.innerText));
            } else {
                ship_to_add["val"] = shipData[target.innerText]['d']
                setDefenseAvailable((prevDefAval) => prevDefAval.filter(unit => unit !== target.innerText));
            }
        }

        if (target.dataset.side === "0") {
            setAttackUnits((prevAtkUnits) => {
                return [...prevAtkUnits, ship_to_add];
            });
        } else {
            setDefenseUnits((prevDefUnits) => {
                return [...prevDefUnits, ship_to_add];
            });
        }
    }
    const decrementCount = (side, index) => {
        if (side === 0){
            setAttackUnits((prevAtkUnits) => {
                if (prevAtkUnits[index]["count"] === 1){
                    return prevAtkUnits;
                } else {
                    return [...prevAtkUnits.slice(0,index),
                        {...prevAtkUnits[index], "count": prevAtkUnits[index]["count"]-1},
                        ...prevAtkUnits.slice(index+1)];
                }
            })
        } else {
            setDefenseUnits((prevDefUnits)=> {
                if (prevDefUnits[index]["count"] === 1) {
                    return prevDefUnits;
                } else {
                    return [...prevDefUnits.slice(0,index),
                        {...prevDefUnits[index], "count": prevDefUnits[index]["count"]-1},
                        ...prevDefUnits.slice(index+1)];
                }
            })
        }
    }

    const incrementCount = (side, index) => {
        if (side === 0){
            setAttackUnits((prevAtkUnits) => {
                return [...prevAtkUnits.slice(0,index),
                    {...prevAtkUnits[index], "count": prevAtkUnits[index]["count"]+1},
                    ...prevAtkUnits.slice(index+1)];
            })
        } else {
            setDefenseUnits((prevDefUnits)=> {
                return [...prevDefUnits.slice(0,index),
                    {...prevDefUnits[index], "count": prevDefUnits[index]["count"]+1},
                    ...prevDefUnits.slice(index+1)];
            })
        }
    }

    const handleRemoveUnit = ( index, side, name) => {
        if (side === 0){
            //attack
            setAttackUnits((prevAtkUnits) => (prevAtkUnits.filter((unitDict, idx) => idx !== index)));
            if (name in shipData) {
                setAttackAvailable((prevAtkAval) => [...prevAtkAval, name]);
            } else {
                setAttackerCapitalShips((prevCap) => [...prevCap, name])
            }
        } else {
            //defense
            setDefenseUnits((prev) => prev.filter((unit) => unit["name"] !== name));
            if (name in shipData) {
                setDefenseAvailable((prev) => [...prev, name]);
            } else {
                setDefenderCapitalShips((prevCap) => [...prevCap, name]);
            }
        }
    }

    const [currentAttackTech, setCurrentAttackTech] = useState("radioBtnX");
    const [currentDefenderTech, setCurrentDefenseTech] = useState("radioBtnX");

    const handleChangeTech = (side, btnid) => {
        if (side === 0) {
            setCurrentAttackTech(btnid);
        } else {
            setCurrentDefenseTech(btnid);
        }
    }

    //update Attack CS
    useEffect(() => {
        if (currentAttackTech in techDict["capitalTechs"]) {
            //Switching to Super Battleships
            if (currentAttackTech === "radioBtn1" ) {
                //Last Tech was Super Carriers
                if (avalAtkCapitalShips[0].some((ship) => superACCs.includes(ship)) || attackUnits.some((shipDict) => superACCs.includes(shipDict.name))) {
                    setAttackerCapitalShips([baseACCs, superBSs])
                    setAttackUnits((prevAtk) => prevAtk.filter((unitDict) => unitDict.name in shipData))
                } else {
                    //Already populated with base ACCs
                    setAttackerCapitalShips((prevAval) => [prevAval[0], superBSs])
                    setAttackUnits((prevAtk) => prevAtk.filter((unitDict) => !(baseBSs.includes(unitDict.name))))
                }
            //Switching to Super Carriers
            } else {
                //Last Tech was Super BS
                if (avalAtkCapitalShips[1].some((ship) => superBSs.includes(ship)) || attackUnits.some((shipDict) => superBSs.includes(shipDict.name))) {
                    setAttackerCapitalShips([superACCs, baseBSs]);
                    setAttackUnits((prevAtk) => prevAtk.filter((unitDict) => unitDict.name in shipData))
                } else {
                    //already populared with base BS
                    setAttackerCapitalShips((prevAval) => [superACCs, prevAval[1]])
                    setAttackUnits((prevAtk) => prevAtk.filter((unitDict) => !(baseACCs.includes(unitDict.name))))
                }
            }
        } else {
            //check if switching from capital tech
            if (avalAtkCapitalShips[0].some((ship) => superACCs.includes(ship)) || attackUnits.some((shipDict) =>  superACCs.includes(shipDict.name))){
                setAttackerCapitalShips((prevAval) => [baseACCs, prevAval[1]])
                setAttackUnits((prevAtk) => prevAtk.filter((unitDict) => !(superACCs.includes(unitDict.name))))
            } else if (avalAtkCapitalShips[1].some((ship) => superBSs.includes(ship)) || attackUnits.some(shipDict => superBSs.includes(shipDict.name))) {
                setAttackerCapitalShips((prevAval) => [prevAval[0], baseBSs])
                setAttackUnits((prevAtk) => prevAtk.filter((unitDict) => !(superBSs.includes(unitDict.name))))
            }
        }
    }, [currentAttackTech]);

    //Update Defender Capital ships
    useEffect(() => {
        if (currentDefenderTech in techDict["capitalTechs"]) {
            //Switching to Super Battleships
            if (currentDefenderTech === "radioBtn1" ) {
                //Last Tech was Super Carriers
                if (avalDefCapitalShips[0].some((ship) => superACCs.includes(ship)) || defenseUnits.some((shipDict) => superACCs.includes(shipDict.name))) {
                    setDefenderCapitalShips([baseACCs, superBSs])
                    setDefenseUnits((prevDef) => prevDef.filter((unitDict) => unitDict.name in shipData))
                } else {
                    //Already populated with base ACCs
                    setDefenderCapitalShips((prevAval) => [prevAval[0], superBSs])
                    setDefenseUnits((prevDef) => prevDef.filter((unitDict) => !(baseBSs.includes(unitDict.name))))
                }
            //Switching to Super Carriers
            } else {
                //Last Tech was Super BS
                if (avalDefCapitalShips[1].some((ship) => superBSs.includes(ship)) || defenseUnits.some((shipDict) => superBSs.includes(shipDict.name))) {
                    setDefenderCapitalShips([superACCs, baseBSs]);
                    setDefenseUnits((prevDef) => prevDef.filter((unitDict) => unitDict.name in shipData))
                } else {
                    //already populared with base BS
                    setDefenderCapitalShips((prevAval) => [superACCs, prevAval[1]])
                    setDefenseUnits((prevDef) => prevDef.filter((unitDict) => !(baseACCs.includes(unitDict.name))))
                }
            }
        } else {
            //Was super ACCs
            if (avalDefCapitalShips[0].some((ship) => superACCs.includes(ship)) || defenseUnits.some((shipDict) => superACCs.includes(shipDict.name))){
                setDefenderCapitalShips((prevAval) => [baseACCs, prevAval[1]])
                setDefenseUnits((prevDef) => prevDef.filter((unitDict) => !(superACCs.includes(unitDict.name))))
            //Was super BS
            } else if (avalDefCapitalShips[1].some((ship) => superBSs.includes(ship)) || defenseUnits.some((shipDict) => superBSs.includes(shipDict.name))) {
                setDefenderCapitalShips((prevAval) => [prevAval[0], baseBSs])
                setDefenseUnits((prevDef) => prevDef.filter((unitDict) => !(superBSs.includes(unitDict.name))))
            }
        }
    }, [currentDefenderTech]);
    
    //Chart Generation
    const [isLoading, setLoading] = useState(false);
    const [isResultsPopulated, setPopulated] = useState(false);

    //Show Response Message
    const [currentStatus, setStatus] = useState([]);
    const [winner, setWinner] = useState(-1);

    //Populate Pie Chart data
    const [battleData, setBattleData] = useState([]);

    const run = () => {
        setLoading(true);
        let url = new URL('/api/navalsim', baseURL);

        let atkDict= {};
        let dfdDict = {};
        attackUnits.forEach((unit)=> {
            atkDict[unit.name] = unit.count
        })
        defenseUnits.forEach((unit)=> {
            dfdDict[unit.name] = unit.count
        })

        let bodyData = {
            "atkTech": currentAttackTech,
            "dfdTech": currentDefenderTech,
            "atkUnits": atkDict,
            "dfdUnits": dfdDict,
            "singleRound": singleRound,
            "numberTS": numberTS
        }

        //make api call in useEffect hook?
        fetch(url, {
            "method" : "POST",
            "headers": {"Content-Type": "application/json"},
            "body" : JSON.stringify(bodyData)
        })
            .then(response=> response.json())
            .then(data => {
                setLoading(false);
                updateCurrentResults(data);
            })
            .catch(error => {
                setLoading(false);
                console.error(error);
            });
    }

    //Only show charts once battleData has updated
    useEffect(() => {
        if (battleData.length > 0) {
            setPopulated(true);
            const resultsMessage = document.getElementById('resultsMessage');
            resultsMessage.scrollIntoView({behavior: 'smooth'});
        }
    }, [battleData])

    const updateCurrentResults = (data) => {

        const RED = 'rgba(207, 20, 6, 0.8)';
        const RED_FADED = 'rgba(207, 20, 6, 0.6)';
        const BLUE = 'rgba(48, 82, 219, 0.8)';
        const BLUE_FADED = 'rgba(48, 82, 219, 0.6)';
        const GREEN = 'rgba(33, 163, 59, 0.8)';
        const YELLOW = 'rgba(237, 237, 55, 0.8)';
        const YELLOW_FADED = 'rgba(237, 237, 55, 0.6)';

        //Win Frequency (Pie) Chart
        const winFreqData = {
            labels: ['Attacker', 'Defender', 'Draw', 'Stalemate'],
            datasets: [{
                label: 'Frequency',
                data: [data.results.attacker, data.results.defender, data.results.draw, data.results.stalemate],
                backgroundColor: [
                    RED,
                    BLUE,
                    GREEN,
                    YELLOW
                ],
                borderWidth: 3
            }]
        };

        //IPC Loss Data (Bar) Chart
        let atk_ave_loss = 0;
        data.atk_outcomes.forEach((outcome) => {
            atk_ave_loss += outcome.freq * outcome.ipc_loss
        });

        let def_ave_loss = 0;
        data.def_outcomes.forEach((outcome) => {
            def_ave_loss += outcome.freq * outcome.ipc_loss
        });
        const ipcData = {
            labels: ["Attacker", "Defender"],
            datasets: [
                {
                    label: "Attacker IPC Loss",
                    data: [atk_ave_loss, NaN],
                    borderColor: RED,
                    backgroundColor: RED_FADED,
                    borderWidth: 5,

                },
                {
                    label: "Defender IPC Loss",
                    data: [NaN, def_ave_loss],
                    borderColor: BLUE,
                    backgroundColor: BLUE_FADED,
                    borderWidth: 5,
                }
            ]
        }

        //Troops Remaining (Graph) Chart (Atk & Def)
        const atk_outcome_sorted = data.atk_outcomes.sort(function(first, second) {
            return first.size - second.size;
        });
        const atk_outcome_labels = atk_outcome_sorted.map((outcome) => outcome.outcome_str);
        const atk_outcome_freq = atk_outcome_sorted.map((outcome) => outcome.freq);
        const atk_outcome_loss = atk_outcome_sorted.map((outcome) => outcome.ipc_loss);

        const attackSurvivorData = {
            labels: atk_outcome_labels,     //Outcome Strings
            datasets: [{
                    type: 'bar',
                    label: 'Frequency',
                    data: atk_outcome_freq,     //Frequencies
                    borderColor: RED,
                    backgroundColor: RED_FADED,
                    borderWidth: 3,
                    yAxisID: 'y'
                },
                {
                    type: 'line',
                    label: 'IPCs Lost',
                    data: atk_outcome_loss,     //IPC loss
                    borderColor: YELLOW,
                    backgroundColor: YELLOW_FADED,
                    borderWidth: 2,
                    yAxisID: 'y1'
                }
            
            ]
        };

        const def_outcome_sorted = data.def_outcomes.sort(function(first, second) {
            return first.size - second.size;
        });
        const def_outcome_labels = def_outcome_sorted.map((outcome) => outcome.outcome_str);
        const def_outcome_freq = def_outcome_sorted.map((outcome) => outcome.freq);
        const def_outcome_loss = def_outcome_sorted.map((outcome) => outcome.ipc_loss);

        const defenderSurvivorData = {
            labels: def_outcome_labels,
            datasets: [{
                    type: 'bar',
                    label: 'Frequency',
                    data: def_outcome_freq,     //Frequencies
                    borderColor: BLUE,
                    backgroundColor: BLUE_FADED,
                    borderWidth: 3,
                    yAxisID: 'y'
                },
                {
                    type: 'line',
                    label: 'IPCs Lost',
                    data: def_outcome_loss,     //IPC loss
                    borderColor: YELLOW,
                    backgroundColor: YELLOW_FADED,
                    borderWidth: 2,
                    yAxisID: 'y1'
                }
            ]
        };

        const results = [winFreqData, ipcData, attackSurvivorData, defenderSurvivorData];
        setBattleData(results);
        setWinner(data.results.attacker > data.results.defender ? 0: (data.results.defender > data.results.attacker ? 1 : -1));
        setStatus([`Attacker Wins ${data.results.attacker.toFixed(2)}%`, `Defender Wins ${data.results.defender.toFixed(2)}%`]);
    }
    
    const handleClear = () => {
        //reset tech
        setCurrentAttackTech("radioBtnX");
        setCurrentDefenseTech("radioBtnX");
        //reset available
        setAttackAvailable(Object.keys(shipData).filter((key) => key !== "Transport"));
        setDefenseAvailable(Object.keys(shipData).filter((key) => key !== "Strategic Bomber"));
        //capital ships
        setAttackerCapitalShips([baseACCs, baseBSs]);
        setDefenderCapitalShips([baseACCs, baseBSs]);
        //reset active
        setAttackUnits([]);
        setDefenseUnits([]);
    }

    const handleSwap = () => {
        //store state
        let prevAtkUnits = attackUnits;
        let prevDefUnits = defenseUnits;

        let atkTech = currentAttackTech;
        let dfdTech = currentDefenderTech;

        let allAtkAval = Object.keys(shipData).filter((key) => key !== "Transport");
        let allDefAval = Object.keys(shipData).filter((key) => key !== "Strategic Bomber");
        
        let allAtkCaps = avalAtkCapitalShips;
        let allDfdCaps = avalDefCapitalShips;

        //set tech
        setCurrentDefenseTech(atkTech);
        setCurrentAttackTech(dfdTech);

        let copyDict;
        let indexOf;

        let newAtk = []
        prevDefUnits.forEach(troopDict => {
            if (!(troopDict["name"] === "Transport")){
                copyDict = {
                    "name": troopDict["name"],
                    "count": troopDict["count"]
                }
                if (troopDict["name"] in capitalShips){
                    copyDict["val"] = capitalShips[troopDict["name"]]["a"];
                    copyDict["hp"] = capitalShips[troopDict["name"]]["hp"];
                } else {
                    copyDict["val"] = shipData[troopDict["name"]]["a"];
                    indexOf = allAtkAval.indexOf(troopDict["name"]);
                    allAtkAval.splice(indexOf, 1);
                }
                newAtk.push(copyDict);
            }
        });

        let newDfd = [];
        prevAtkUnits.forEach(troopDict => {
            if (!(troopDict["name"] === "Strategic Bomber")){
                copyDict = {
                    "name": troopDict["name"],
                    "count": troopDict["count"]
                }
                if (troopDict["name"] in capitalShips){
                    copyDict["val"] = capitalShips[troopDict["name"]]["d"];
                    copyDict["hp"] = capitalShips[troopDict["name"]]["hp"];

                } else {
                    copyDict["val"] = shipData[troopDict["name"]]["d"];
                    indexOf = allDefAval.indexOf(troopDict["name"]);
                    allDefAval.splice(indexOf, 1);
                }
                newDfd.push(copyDict);
            }
        });
        //timeout to allow tech change to propogate
        setTimeout( () => {
            //set troops
            setAttackUnits(newAtk);
            setDefenseUnits(newDfd);
            //set available units
            setAttackAvailable(allAtkAval);
            setDefenseAvailable(allDefAval);
            //set capital ships
            setAttackerCapitalShips(allDfdCaps);
            setDefenderCapitalShips(allAtkCaps);
        }, 100)
    }

    const handleChangeCount = (value, side, index) => {
        if (typeof parseInt(value) === "number") {
            value = parseInt(value);
            if (value < 1){
                value = 1;
            }
            if (side === 0){
                setAttackUnits((prevAtkUnits) => {
                    return [...prevAtkUnits.slice(0,index),
                        {...prevAtkUnits[index], "count": Number(value)},
                        ...prevAtkUnits.slice(index+1)];
                })
            } else {
                setDefenseUnits((prevDefUnits)=> {
                    return [...prevDefUnits.slice(0,index),
                        {...prevDefUnits[index], "count": Number(value)},
                        ...prevDefUnits.slice(index+1)];
                })
            }
        }
    }

    const toggleSingleRound = () => {
        setSingleRound(prevState => !prevState);
    }

    const toggleShowOptions = () => {
        setShowOptions(prev => !prev);
    }

    const [showSearch, setShowSearch] = useState(false);
    const [showSave, setShowSave] = useState(false);

    const toggleSearch = () => {
        setShowSearch(prev => !prev);
    }

    const toggleShowSave = () => {
        setShowSave(prev => !prev);
    }

    /*Fetch state from history*/
    const fetchHistory = async (uid) => {
        //Make call to backend, return success for modal to close
        let url = new URL('/api/history/get', baseURL);
        let body = {'uid': uid, 'origin': 'sea'}
        
        try {
            const response = await fetch(url, {
                "method" : "POST",
                "headers": {"Content-Type": "application/json"},
                "body" : JSON.stringify(body)
            })
            
            const data = await response.json()
            if ("error" in data){
                return {
                    success: false,
                    error: data.error
                };
            }
            //Dont render unit list while updating states
            setAllowUnitListRender(false);
            setTimeout(()=>{
                setCurrentAttackTech(prev => data.state.attackTech);
                setCurrentDefenseTech(prev => data.state.defenseTech);
                setAttackAvailable(prev => data.state.attackAvailable);
                setDefenseAvailable(prev => data.state.defenseAvailable);
                setAttackUnits(prev => data.state.attackUnits);
                setDefenseUnits(prev => data.state.defenseUnits);
                setAttackerCapitalShips(prev => data.state.attackerCapitalShips)
                setDefenderCapitalShips(prev => data.state.defenderCapitalShips)
            }, 200)
            
            //Delay render
            setTimeout(()=>{
                setAllowUnitListRender(true);
            }, 500);

            //Shallow hide results
            setPopulated(false);

            return {
                success: true
            };
        } catch (error) {
            console.error(error);

            return {
                success: false,
                error: error
            };
        };
    }

    const saveToHistory = async (uid) => {
        //Make call to backend, return success for modal to close
        let url = new URL('/api/history/put', baseURL);
        const state = {
            'attackAvailable': attackAvailable,
            'defenseAvailable': defenseAvailable,
            'attackUnits': attackUnits,
            'defenseUnits': defenseUnits,
            'attackTech': currentAttackTech,
            'defenseTech': currentDefenderTech,
            'attackerCapitalShips': avalAtkCapitalShips,
            'defenderCapitalShips': avalDefCapitalShips
        };

        let body = {'uid': uid, 'origin': 'sea', 'state': state}

        try {
            const response = await fetch(url, {
                "method" : "POST",
                "headers": {"Content-Type": "application/json"},
                "body" : JSON.stringify(body)
            });
            const data = await response.json()

            if ("error" in data){
                return {
                    success: false,
                    error: data.error
                };
            } else {
                return {
                    success: true
                };
            }
        } catch (error) {

            console.error(error);

            return {
                success: false,
                error: error
            };
        };
    }


    const [numberTS, setNumberTS] = useState(0);
    const [targetSelectActive, setTargetSelectActive] = useState(false);

    const getMaxTS = () => {
        const numTacBombers = attackUnits.filter(unit => unit.name === "Tactical Bomber")[0].count
        return numTacBombers
    }

    const handleToggleTargetSelect = () => {
        setTargetSelectActive(prev => !prev);
    }

    const isTargetSelectValid = () => {
        const selectables = [
            'Cruiser',
            'Destroyer',
            ...baseACCs,
            ...superACCs,
            'Damaged Battleship',
            'Damaged Super Battleship'
        ]

        if (defenseUnits.filter(unit => baseBSs.includes(unit.name) || superBSs.includes(unit.name)).length > 0){
            //Defender has AA
            setTargetSelectActive(false);
            return false;
        } else if (defenseUnits.filter(unit => selectables.includes(unit.name)).length === 0) {
            //No selectable defenders
            setTargetSelectActive(false);
            return false;
        } else if (attackUnits.filter(unit => unit.name === "Tactical Bomber").length === 0){
            //No attacking tac bombers
            setTargetSelectActive(false);
            return false;
        } else {
            return true
        }
    }

    return (
        <div className="container-lg pt-3">  
            <TitleBar toggleSearch={toggleSearch} title="Naval Battle Calculator"/>
            <div id="resultsMessage" className="text-center my-2">
            {isResultsPopulated &&
                <>
                    <h1>
                        <span style={{fontWeight: 'bold', color: winner === 0 ? 'red' : (winner === 1 && 'blue')}}>{currentStatus[Math.abs(winner)]}</span> {currentStatus[(winner + 3)%2]}
                    </h1>
                    <hr className={`border border-3 opacity-75 ${winner === 0 ? 'border-danger' : (winner === 1 ? 'border-primary' : 'border-success')}`} />
                    <div className="container-lg mb-3">
                        <div className="row mb-3">
                            <div className="col-12 col-md-6 d-flex justify-content-center chart-container"
                                style={{"maxHeight": 357}}>
                                <WinChart chartData={battleData[0]} />
                            </div>
                            <div className="col-12 col-md-6 d-flex justify-content-center chart-container mt-3 mt-md-0"
                                style={{"maxHeight": 357}}>
                                <IpcChart chartData={battleData[1]}/>
                            </div>                        
                        </div>
                        <div className="row mb-5 mb-md-0" >
                            <div className="col-12 col-md-6 d-flex justify-content-center chart-container "
                                style={{"maxHeight": 357}}>
                                <SurvivorChart chartData={battleData[2]} title="Attacking Troops Remaining" />
                            </div>
                            <div className="col-12 col-md-6 mt-3 mt-md-0 d-flex justify-content-center chart-container"
                                style={{"maxHeight": 357}}>
                                <SurvivorChart chartData={battleData[3]} title="Defending Troops Remaining" />
                            </div>
                        </div>
                    </div>
                </>
            }
            </div>
            <OptionsDropdown 
                showOptions={showOptions}
                toggleShow={toggleShowOptions}
                singleRoundState={singleRound} 
                toggleSingleRound={toggleSingleRound}
                isTargetSelectValid={isTargetSelectValid}
                numberTS={numberTS}
                setNumberTS={setNumberTS}
                getMaxTS={getMaxTS}
                targetSelectActive={targetSelectActive}
                handleToggleTargetSelect={handleToggleTargetSelect}
            />
            <div className = {`container-fluid mb-3 justify-content-center bg-dark text-white ${styles.inputBox} ${styles.defaultBox}`}>
                <h4><strong>Select Tech:</strong></h4>
                <div className='d-flex justify-content-around py-2'>
                    <SelectTechInput 
                        techList={seaTech}
                        currentTech={currentAttackTech}
                        handleChangeTech={handleChangeTech}
                        side={0}
                    />
                    <SelectTechInput 
                        techList={seaTech}
                        currentTech={currentDefenderTech}
                        handleChangeTech={handleChangeTech}
                        side={1}
                    />
                </div>
                <h4><strong>Select Units:</strong></h4>
                <AddTroopsInput 
                    attackAvailable={attackAvailable} 
                    bombardsAvailable={null} 
                    defenseAvailable={defenseAvailable}
                    handleAddUnit={handleAddUnit}
                    availableAtkCS={avalAtkCapitalShips}
                    availableDefCS={avalDefCapitalShips}
                />
            </div>
            <div className="container-fluid my-3 px-0 px-sm-2">
                {allowUnitListRender &&
                    <CurrentUnitList 
                        attackUnits={attackUnits} 
                        defenseUnits={defenseUnits}
                        currentAtkTech={currentAttackTech}
                        currentDefTech={currentDefenderTech}
                        techList={techDict}
                        handleRemoveUnit={handleRemoveUnit}
                        handleIncrement={incrementCount}
                        handleDecrement={decrementCount}
                        handleChange={handleChangeCount}
                    />
                }
            </div>

            <UtilityBtns handleClear={handleClear} handleSwap={handleSwap} handleShowSave={toggleShowSave} isPopulated={isResultsPopulated}/>

            <div className="container-fluid my-3 text-center">
                <button id="run" className="btn btn-success" style={{"width":"10em", "height": "3em"}} onClick={run}>
                    {isLoading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> :
                    <span style={{"fontSize":22}}>Run</span>}
                </button>
            </div>

            {showSearch &&
                <SearchModal setShow={setShowSearch} runSearch={fetchHistory}/>
            }

            {showSave &&
                <SaveModal setShow={setShowSave} runSave={saveToHistory}/>    
            }
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
}

export default SeaPage;