import React from 'react';
import { Link } from 'react-router-dom';
import { Avatar } from '@mui/material';
import { RecentUpdates } from '../components/Updates';
import '../styles/fonts.css';
import animationData from "../lotties/profile.json"
import Lottie from 'react-lottie';

function HomePage() {

    const animationOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    }

    return (
        <div style={{fontFamily: 'NunitoSans, sans-serif'}} className="container-lg py-4">
            <div className="d-md-flex p-1 align-items-center text-center">
                <div>
                        <Avatar
                            className='mx-auto'
                            alt="Dubsy profile"
                            src="/thumbnails/dubsy_profile.png"
                            sx={{width:100, height: 100}}
                        />
                </div>    
                <div className='flexGrow-1 mx-auto'>
                    <h2 className='mt-2'> Welcome to Dubsy's BBR Battle Calculator</h2>
                    <p className='pt-1'>
                        This is a battle odds calculator specific to the BBR variant of Axis and Allies.
                        <br/>
                        For more on BBR check out&nbsp;
                        <a href="http://www.siredblood.com/community" target="_blank" rel="noreferrer" style={{"textDecoration": "none"}}>SiredBlood's website</a>.
                    </p>
                </div>
            </div>
            <div className='d-flex-column justify-content-center mt-4'>
                <RecentUpdates/>
            </div>
            <div className='pt-4 px-3'>
                <h3>Contact</h3>
            </div>
            <div className="d-flex">
                <div className='pt-2'>
                    <div className='text-center'>
                        <Lottie 
                            options={animationOptions}
                            height={100}
                            width={100}
                        />
                    </div>
                </div>
                <div className='mt-3 px-3'>
                    <p>
                        Please reach out if you have any requests or feedback.
                        <br/>
                        See you all in St.Louis!
                    </p>
                    <p>
                        Email: <EmailLink />
                    </p>
                </div>
            </div>
        </div>
    );
}

function EmailLink() {
    const email = 'haydenwhitley2001@gmail.com';
    const subject = 'BBR Battle Calculator';
    //construct mailto URL
    const mailToUrl = `mailto:${email}?subject=${encodeURIComponent(subject)}`;

    return <a href={mailToUrl} style={{"textDecoration":"none"}}>here</a>
}

export default HomePage;