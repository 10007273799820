import Modal from 'react-bootstrap/Modal';

//Add to input bar
import Fingerprint from '@mui/icons-material/Fingerprint';
import { TextField } from '@mui/material';
import { Box } from '@mui/material';

import SearchIcon from '@mui/icons-material/Search';

import { useState } from 'react';
import IconButton from '@mui/material/IconButton';

import Lottie from 'react-lottie';
import successAnimationData from '../lotties/searchSuccess';
import loadingAnimationData from '../lotties/loading';

import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from 'react-bootstrap/esm/OverlayTrigger';

export function SearchModal({setShow, runSearch}) {
    const [searchString, setSearchString] = useState("");
    const [errorMsg, setErrorMsg] = useState("");
    const [showAnimation, setShowAnimation] = useState(false);
    const [isLoading, setLoading] = useState(false);

    const tooltip = (value) => {
      return (
          <Tooltip>
              {value}
          </Tooltip>
      );
  };
    
    const handleRunSearch = async () => {
      setLoading(true);
      const result =  await runSearch(searchString);
      setLoading(false);
      if (result.success){
        setShowAnimation(true);
      } else {
        setErrorMsg(result.error);
      }
    }

    const handleChange = (value) => {
      setSearchString(value);
    }

    const handleClose = () => setShow(false);

    
    const successOptions = {
      loop: false,
      autoplay: true,
      animationData: successAnimationData,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
      }
    };

    const loadingOptions = {
      loop: true,
      autoplay: true,
      animationData: loadingAnimationData,
      rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
      }
    };
  
    return (
      <Modal show={true} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Search History</Modal.Title>
        </Modal.Header>
        {
          (isLoading)
        ?
        <Modal.Body>
          < div>
            <Lottie 
                options={loadingOptions}
                height={300}
                width={300}
            />
          </div>
        </Modal.Body>
        :
          (showAnimation)
        ? 
          <div>
            <Lottie 
              options={successOptions}
              height={300}
              width={300}
              eventListeners={[
                {
                  eventName: 'complete',
                  callback: () => handleClose()
                }
              ]}
            />
          </div>
        :
          <>
            <Modal.Body>
              Locate a Previously Saved Battle by its ID:
              <br />
              <Box className="mt-2" sx={{ display: 'flex', alignItems: 'flex-end' }}>
                <Fingerprint sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                <TextField 
                  id="input-with-sx" 
                  label="Search Battle ID..." 
                  variant="standard" 
                  value={searchString}
                  onChange={e => handleChange(e.target.value)}
                />
              </Box>

              {errorMsg !== "" && 
                <p className='text-danger'>{errorMsg}</p>
              }
            </Modal.Body>
            <Modal.Footer>
              <OverlayTrigger placement="top" overlay={tooltip("Search")} delay={200}>
                <IconButton onClick={handleRunSearch} color="success" aria-label="Run search">
                  <SearchIcon sx={{fontSize: 30}}/>
                </IconButton>
              </OverlayTrigger>
            </Modal.Footer>
          </>
        }
      </Modal>
    );
  }