import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

import IconButton from '@mui/material/IconButton';
import SaveIcon from '@mui/icons-material/SaveAlt';
import Swap from '@mui/icons-material/SwapHoriz';
import Clear from '@mui/icons-material/DeleteForever'

import classNames from 'classnames'


function UtilityBtns({handleClear, handleSwap, handleShowSave, isPopulated}) {
    const tooltip = (value) => {
        return (
            <Tooltip>
                {value}
            </Tooltip>
        );
    };

    return (
        <div className="d-flex flex-row-reverse">
            <OverlayTrigger placement="bottom" overlay={tooltip("Clear")} delay={200}>
                <IconButton className="mx-1" onClick={handleClear} color="error" aria-label="Clear">
                    <Clear sx={{fontSize: 30}} />
                </IconButton>
            </OverlayTrigger>
            <OverlayTrigger placement="bottom" overlay={tooltip("Swap")} delay={200}>
                <IconButton className="mx-1" onClick={handleSwap} aria-label="Swap">
                    <Swap sx={{fontSize: 30}} />
                </IconButton>
            </OverlayTrigger>

            <OverlayTrigger placement="bottom" overlay={tooltip("Save Battle")} delay={200}>
                <IconButton className="mx-1" onClick={handleShowSave} color="primary" aria-label="Save">
                    <SaveIcon sx={{fontSize: 30}}/>
                </IconButton>
            </OverlayTrigger>

            <div className="px-3" style={{"flexGrow": "1"}}>
                <hr 
                className={
                    classNames({'text-success': isPopulated})
                }
                style={{"border": "3px solid black"}}/>
            </div>
        </div>
    );
}

export default UtilityBtns;