import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

import TitleWriter from "../components/TitleWriter";
import HistoryIcon from '@mui/icons-material/History';
import { IconButton } from '@mui/material';

export function TitleBar({ toggleSearch, title }) {
    const tooltip = (value) => {
        return (
            <Tooltip>
                {value}
            </Tooltip>
        );
    };

    return (
        <>
        <div className="row align-items-center justify-content-center">
            <div className="col-11 col-xl-11 text-center">
                <TitleWriter title={title}/>
            </div>
            <div className="col text-center">
                <OverlayTrigger placement="top" overlay={tooltip("Search History")} delay={200}>
                    <IconButton onClick={toggleSearch} color="primary" aria-label="Search History">
                    <HistoryIcon sx={{fontSize: 30}}/> <span className="">&nbsp;Load Battle</span>
                    </IconButton>
                </OverlayTrigger>
            </div>
        </div>
        <div className="row">
            <div className="col-12">
                <hr/>
            </div>
        </div>
        </>
    )
}