import React, { useState,useEffect } from 'react';

import SelectTechInput from '../containers/SelectTechInput';
import AddTroopsInput from '../containers/AddTroopsInput';
import CurrentUnitList from '../containers/CurrentUnitList';

import styles from '../inputBox.module.css';

import troopData from '../data/troopProfiles.json';
import bombardData from '../data/bombardProfiles.json';

import WinChart from '../graphs/WinChart';
import SurvivorChart from '../graphs/SurvivorChart';
import IpcChart from '../graphs/IpcChart';
import UtilityBtns from '../components/UtilityBtns';
import OptionsDropdown from '../components/TuneSettings';

import { TitleBar } from '../components/TitleBar';

import { SearchModal } from '../components/SearchModal';
import { SaveModal } from '../components/SaveModal';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

function LandPage({ baseURL}) {
    const landTech = {
        "Advanced Artillery": "radioBtn0", 
        "ATC/Radar": "radioBtn1", 
        "Heavy Bombers": "radioBtn2",
        "Heavy Tanks": "radioBtn3", 
        "Jet Fighters": "radioBtn4",
        "Super Battleships": "radioBtn5", 
        "No Tech": "radioBtnX"
    };

    const techDict = {
            "radioBtn0": {"efx": "Artillery", 0 : 2, 1 : 2},
            "radioBtn1": {"efx": "AAA", 0 : null, 1 : [2,2,2]},
            "radioBtn2": {"efx": "Strategic Bomber", 0 : [3,3], 1 : 1},
            "radioBtn3": {"efx": "Tank", 0 : 4, 1 : 3},
            "radioBtn4": {"efx": "Fighter", 0 : 4, 1 : 4},
            "radioBtn5": {"efx": "Battleship Bombard", 0 : [4,2], 1 : null},
            "radioBtnX": {"efx": null}
        };

    const [attackAvailable, setAttackAvailable] = useState(Object.keys(troopData).filter((key) => key !== "AAA"));
    const [defenseAvailable, setDefenseAvailable] = useState(Object.keys(troopData));
    const [bombardsAvailable, setBombardsAvailable] = useState(Object.keys(bombardData));

    const [attackUnits, setAttackUnits] = useState([]);
    const [defenseUnits, setDefenseUnits] = useState([]);

    const [singleRound, setSingleRound] = useState(false);
    const [showOptions, setShowOptions] = useState(false);
    const [allowUnitListRender, setAllowUnitListRender] = useState(true);

    const handleAddUnit =  ({ target }) => {
        if (target.dataset.side === "0") {
            if (target.innerText in troopData){
                setAttackUnits((prevAtkUnits) => (
                    [...prevAtkUnits, {
                            "name" : target.innerText,
                            "count" : 1,
                            "val" : troopData[target.innerText]['a']
                        }
                    ]
                ));
                setAttackAvailable((prevAtkAval) => prevAtkAval.filter(unit => unit !== target.innerText));
            } else {
                setAttackUnits((prevAtkUnits) => (
                    [...prevAtkUnits, {
                            "name" : target.innerText,
                            "count" : 1,
                            "val" : bombardData[target.innerText]['a']
                        }
                    ]
                ));
                setBombardsAvailable((prevBombAval) => prevBombAval.filter(unit => unit !== target.innerText));
            } 
        } else {
            setDefenseUnits((prevDefUnits) => (
                [...prevDefUnits, {
                        "name" : target.innerText,
                        "count" : 1,
                        "val" : troopData[target.innerText]['d']
                    }
                ]
            ));
            setDefenseAvailable((prevDefAval) => prevDefAval.filter(unit => unit !== target.innerText));
        }
    }

    const decrementCount = (side, index) => {
        if (side === 0){
            setAttackUnits((prevAtkUnits) => {
                if (prevAtkUnits[index]["count"] === 1){
                    return prevAtkUnits;
                } else {
                    return [...prevAtkUnits.slice(0,index),
                        {...prevAtkUnits[index], "count": prevAtkUnits[index]["count"]-1},
                        ...prevAtkUnits.slice(index+1)];
                }
            })
        } else {
            setDefenseUnits((prevDefUnits)=> {
                if (prevDefUnits[index]["count"] === 1) {
                    return prevDefUnits;
                } else {
                    return [...prevDefUnits.slice(0,index),
                        {...prevDefUnits[index], "count": prevDefUnits[index]["count"]-1},
                        ...prevDefUnits.slice(index+1)];
                }
            })
        }
    }

    const incrementCount = (side, index) => {
        if (side === 0){
            setAttackUnits((prevAtkUnits) => {
                return [...prevAtkUnits.slice(0,index),
                    {...prevAtkUnits[index], "count": prevAtkUnits[index]["count"]+1},
                    ...prevAtkUnits.slice(index+1)];
            })
        } else {
            setDefenseUnits((prevDefUnits)=> {
                return [...prevDefUnits.slice(0,index),
                    {...prevDefUnits[index], "count": prevDefUnits[index]["count"]+1},
                    ...prevDefUnits.slice(index+1)];
            })
        }
    }

    const handleRemoveUnit = ( index, side, name) => {
        if (side === 0){
            //attack
            setAttackUnits((prevAtkUnits) => (prevAtkUnits.filter((unitDict, idx) => idx !== index)));
            if (name in troopData){
                setAttackAvailable((prevAtkAval) => [...prevAtkAval, name]);
            } else {
                setBombardsAvailable((prevBombAval) => [...prevBombAval, name])
            }
        } else {
            //defense
            setDefenseUnits((prev) => prev.filter((unit) => unit["name"] !== name));
            setDefenseAvailable((prev) => [...prev, name]);
        }
    }

    const [currentAttackTech, setCurrentAttackTech] = useState("radioBtnX");
    const [currentDefenderTech, setCurrentDefenseTech] = useState("radioBtnX");

    const handleChangeTech = (side, btnid) => {
        if (side === 0) {
            setCurrentAttackTech(btnid);
        } else {
            setCurrentDefenseTech(btnid);
        }
    }

    const [isLoading, setLoading] = useState(false);
    const [isResultsPopulated, setPopulated] = useState(false);

    //Show Response Message
    const [currentStatus, setStatus] = useState([]);
    const [winner, setWinner] = useState(-1);

    //Populate Pie Chart data
    const [battleData, setBattleData] = useState([]);

    const run = () => {
        setLoading(true);
        let url = new URL('/api/landsim', baseURL);

        let atkDict= {};
        let dfdDict = {};
        attackUnits.forEach((unit)=> {
            atkDict[unit.name] = unit.count
        })
        defenseUnits.forEach((unit)=> {
            dfdDict[unit.name] = unit.count
        })

        //Validate Target Select
        let validatedTS = numberTS;
        if (!isTargetSelectValid()){
            validatedTS = 0;
        }

        let bodyData = {
            "atkTech": currentAttackTech,
            "dfdTech": currentDefenderTech,
            "atkUnits": atkDict,
            "dfdUnits": dfdDict,
            "singleRound": singleRound,
            "numberTS": numberTS
        }

        //make api call in useEffect hook?
        fetch(url, {
            "method" : "POST",
            "headers": {"Content-Type": "application/json"},
            "body" : JSON.stringify(bodyData)
        })
            .then(response=> response.json())
            .then(data => {
                setLoading(false);
                updateCurrentResults(data);
            })
            .catch(error => {
                setLoading(false);
                console.error(error);
            });
    }

    //Only show charts once battleData has updated
    useEffect(() => {
        if (battleData.length > 0) {
            setPopulated(true);
            const resultsMessage = document.getElementById('resultsMessage');
            resultsMessage.scrollIntoView({behavior: 'smooth'});
        }
    }, [battleData])

    const updateCurrentResults = (data) => {

        const RED = 'rgba(207, 20, 6, 0.8)';
        const RED_FADED = 'rgba(207, 20, 6, 0.6)';
        const BLUE = 'rgba(48, 82, 219, 0.8)';
        const BLUE_FADED = 'rgba(48, 82, 219, 0.6)';
        const GREEN = 'rgba(33, 163, 59, 0.8)';
        const YELLOW = 'rgba(237, 237, 55, 0.8)';
        const YELLOW_FADED = 'rgba(237, 237, 55, 0.6)';

        //Win Frequency (Pie) Chart
        const winFreqData = {
            labels: ['Attacker', 'Defender', 'Draw'],
            datasets: [{
                label: 'Frequency',
                data: [data.results.attacker, data.results.defender, data.results.draw],
                backgroundColor: [
                    RED,
                    BLUE,
                    GREEN
                ],
                borderWidth: 3
            }]
        };

        //IPC Loss Data (Bar) Chart
        let atk_ave_loss = 0;
        data.atk_outcomes.forEach((outcome) => {
            atk_ave_loss += outcome.freq * outcome.ipc_loss
        });

        let def_ave_loss = 0;
        data.def_outcomes.forEach((outcome) => {
            def_ave_loss += outcome.freq * outcome.ipc_loss
        });
        const ipcData = {
            labels: ["Attacker", "Defender"],
            datasets: [
                {
                    label: "Attacker IPC Loss",
                    data: [atk_ave_loss, NaN],
                    borderColor: RED,
                    backgroundColor: RED_FADED,
                    borderWidth: 5,

                },
                {
                    label: "Defender IPC Loss",
                    data: [NaN, def_ave_loss],
                    borderColor: BLUE,
                    backgroundColor: BLUE_FADED,
                    borderWidth: 5,
                }
            ]
        }

        //Troops Remaining (Graph) Chart (Atk & Def)
        const atk_outcome_sorted = data.atk_outcomes.sort(function(first, second) {
            return first.size - second.size;
        });
        const atk_outcome_labels = atk_outcome_sorted.map((outcome) => outcome.outcome_str);
        const atk_outcome_freq = atk_outcome_sorted.map((outcome) => outcome.freq);
        const atk_outcome_loss = atk_outcome_sorted.map((outcome) => outcome.ipc_loss);

        const attackSurvivorData = {
            labels: atk_outcome_labels,     //Outcome Strings
            datasets: [{
                    type: 'bar',
                    label: 'Frequency',
                    data: atk_outcome_freq,     //Frequencies
                    borderColor: RED,
                    backgroundColor: RED_FADED,
                    borderWidth: 3,
                    yAxisID: 'y'
                },
                {
                    type: 'line',
                    label: 'IPCs Lost',
                    data: atk_outcome_loss,     //IPC loss
                    borderColor: YELLOW,
                    backgroundColor: YELLOW_FADED,
                    borderWidth: 2,
                    yAxisID: 'y1'
                }
            
            ]
        };

        const def_outcome_sorted = data.def_outcomes.sort(function(first, second) {
            return first.size - second.size;
        });
        const def_outcome_labels = def_outcome_sorted.map((outcome) => outcome.outcome_str);
        const def_outcome_freq = def_outcome_sorted.map((outcome) => outcome.freq);
        const def_outcome_loss = def_outcome_sorted.map((outcome) => outcome.ipc_loss);

        const defenderSurvivorData = {
            labels: def_outcome_labels,
            datasets: [{
                    type: 'bar',
                    label: 'Frequency',
                    data: def_outcome_freq,     //Frequencies
                    borderColor: BLUE,
                    backgroundColor: BLUE_FADED,
                    borderWidth: 3,
                    yAxisID: 'y'
                },
                {
                    type: 'line',
                    label: 'IPCs Lost',
                    data: def_outcome_loss,     //IPC loss
                    borderColor: YELLOW,
                    backgroundColor: YELLOW_FADED,
                    borderWidth: 2,
                    yAxisID: 'y1'
                }
            ]
        };

        const results = [winFreqData, ipcData, attackSurvivorData, defenderSurvivorData];
        setBattleData(results);
        setWinner(data.results.attacker > data.results.defender ? 0: (data.results.defender > data.results.attacker ? 1 : -1));
        setStatus([`Attacker Wins ${data.results.attacker.toFixed(2)}%`, `Defender Wins ${data.results.defender.toFixed(2)}%`]);
    }
    
    const handleClear = () => {
        //Reset Available Units
        setAttackAvailable(Object.keys(troopData).filter((key) => key !== "AAA"));
        setDefenseAvailable(Object.keys(troopData));
        setBombardsAvailable(Object.keys(bombardData));
        //Clear Current Units
        setAttackUnits([]);
        setDefenseUnits([]);
        //reset tech
        setCurrentAttackTech("radioBtnX");
        setCurrentDefenseTech("radioBtnX");
    }

    const handleSwap = () => {
        let allAtkAval = Object.keys(troopData).filter((key) => key !== "AAA");
        setBombardsAvailable(Object.keys(bombardData));
        let allDefAval = Object.keys(troopData);

        let prevDef = defenseUnits;
        let prevAtk = attackUnits;

        let indexOf;

        let newAtk = []
        prevDef.forEach(troopDict => {
            if (!(troopDict["name"] === "AAA")){
                newAtk.push({
                    "name": troopDict["name"],
                    "count": troopDict["count"],
                    "val": troopData[troopDict["name"]]['a']
                });
                //remove from available units
                indexOf = allAtkAval.indexOf(troopDict["name"]);
                allAtkAval.splice(indexOf, 1);
            }
        });

        let newDef = []
        prevAtk.forEach(troopDict => {
            if(!(Object.keys(bombardData).includes(troopDict["name"]))){
                newDef.push({
                    "name": troopDict["name"],
                    "count": troopDict["count"],
                    "val": troopData[troopDict["name"]]['d']
                });
                //remove from available units
                indexOf = allDefAval.indexOf(troopDict["name"]);
                allDefAval.splice(indexOf, 1);
            }
        });

        let atkTech = currentAttackTech;
        let dfdTech = currentDefenderTech;

        setCurrentAttackTech(dfdTech);
        setCurrentDefenseTech(atkTech);

        setAttackAvailable(allAtkAval);
        setDefenseAvailable(allDefAval);
        setAttackUnits(newAtk);
        setDefenseUnits(newDef);
    }

    const handleChangeCount = (value, side, index) => {
        if (typeof parseInt(value) === "number") {
            value = parseInt(value);
            if (value < 1){
                value = 1;
            }
            if (side === 0){
                setAttackUnits((prevAtkUnits) => {
                    return [...prevAtkUnits.slice(0,index),
                        {...prevAtkUnits[index], "count": value},
                        ...prevAtkUnits.slice(index+1)];
                })
            } else {
                setDefenseUnits((prevDefUnits)=> {
                    return [...prevDefUnits.slice(0,index),
                        {...prevDefUnits[index], "count": value},
                        ...prevDefUnits.slice(index+1)];
                })
            }
        }
    }

    const toggleSingleRound = () => {
        setSingleRound(prevState => !prevState);
    }

    const toggleShowOptions = () => {
        setShowOptions(prev => !prev);
    }

    const [showSearch, setShowSearch] = useState(false);
    const [showSave, setShowSave] = useState(false);

    const toggleSearch = () => {
        setShowSearch(prev => !prev);
    }

    const toggleShowSave = () => {
        setShowSave(prev => !prev);
    }

    /*Fetch state from history*/
    const fetchHistory = async (uid) => {
        //Make call to backend, return success for modal to close
        let url = new URL('/api/history/get', baseURL);
        let body = {'uid': uid, 'origin': 'land'}
        
        try {
            const response = await fetch(url, {
                "method" : "POST",
                "headers": {"Content-Type": "application/json"},
                "body" : JSON.stringify(body)
            })
            
            const data = await response.json()
            if ("error" in data){
                return {
                    success: false,
                    error: data.error
                };
            }
            //Dont render unit list while updating states
            setAllowUnitListRender(false);
            setTimeout(()=>{
                setCurrentAttackTech(prev => data.state.attackTech);
                setCurrentDefenseTech(prev => data.state.defenseTech);
                setAttackAvailable(prev => data.state.attackAvailable);
                setDefenseAvailable(prev => data.state.defenseAvailable);
                setBombardsAvailable(prev => data.state.bombardsAvailable);
                setAttackUnits(prev => data.state.attackUnits);
                setDefenseUnits(prev => data.state.defenseUnits);
            }, 200)
            
            //Delay render
            setTimeout(()=>{
                setAllowUnitListRender(true);
            }, 500);

            //Shallow hide results
            setPopulated(false);

            return {
                success: true
            };
        } catch (error) {
            console.error(error);

            return {
                success: false,
                error: error
            };
        };
    }

    const saveToHistory = async (uid) => {
        //Make call to backend, return success for modal to close
        let url = new URL('/api/history/put', baseURL);
        const state = {
            'attackAvailable': attackAvailable,
            'defenseAvailable': defenseAvailable,
            'bombardsAvailable': bombardsAvailable,
            'attackUnits': attackUnits,
            'defenseUnits': defenseUnits,
            'attackTech': currentAttackTech,
            'defenseTech': currentDefenderTech
        };

        let body = {'uid': uid, 'origin': 'land', 'state': state}

        try {
            const response = await fetch(url, {
                "method" : "POST",
                "headers": {"Content-Type": "application/json"},
                "body" : JSON.stringify(body)
            });
            const data = await response.json()

            if ("error" in data){
                return {
                    success: false,
                    error: data.error
                };
            } else {
                return {
                    success: true
                };
            }
        } catch (error) {

            console.error(error);

            return {
                success: false,
                error: error
            };
        };
    }


    const [numberTS, setNumberTS] = useState(0);
    const [targetSelectActive, setTargetSelectActive] = useState(false);

    const getMaxTS = () => {
        const numTacBombers = attackUnits.filter(unit => unit.name === "Tactical Bomber")[0].count
        return numTacBombers
    }

    const handleToggleTargetSelect = () => {
        setTargetSelectActive(prev => !prev);
    }

    const isTargetSelectValid = () => {
        const selectables = [
            'Artillery',
            'Mechanized Infantry',
            'Tank',
            'Cavalry'
        ]

        if (defenseUnits.filter(unit => unit.name === "AAA").length > 0){
            //Defender has AA
            setTargetSelectActive(false);
            return false;
        } else if (defenseUnits.filter(unit => selectables.includes(unit.name)).length === 0) {
            //No selectable defenders
            setTargetSelectActive(false);
            return false;
        } else if (attackUnits.filter(unit => unit.name === "Tactical Bomber").length === 0){
            //No attacking tac bombers
            setTargetSelectActive(false);
            return false;
        } else {

            return true
        }
    }



    return (
        <div className="container-lg pt-3"> 
            {/*Title*/}
            <TitleBar toggleSearch={toggleSearch} title="Land Battle Calculator"/>
            <div id="resultsMessage" className="text-center my-2">
            {isResultsPopulated &&
                <>
                 <h1>
                    <span style={{fontWeight: 'bold', color: winner === 0 ? 'red' : (winner === 1 && 'blue')}}>{currentStatus[Math.abs(winner)]}</span> {currentStatus[(winner + 3)%2]}
                </h1>
                <hr className={`border border-3 opacity-75 ${winner === 0 ? 'border-danger' : (winner === 1 ? 'border-primary' : 'border-success')}`} />
                <div className="container-lg mb-3">
                    <div className="row mb-3">
                        <div className="col-12 col-md-6 d-flex justify-content-center chart-container"
                            style={{"maxHeight": 357}}>
                            <WinChart chartData={battleData[0]} />
                        </div>
                        <div className="col-12 col-md-6 d-flex justify-content-center chart-container mt-3 mt-md-0"
                            style={{"maxHeight": 357}}>
                            <IpcChart chartData={battleData[1]}/>
                        </div>                        
                    </div>
                    <div className="row mb-5 mb-md-0" >
                        <div className="col-12 col-md-6 d-flex justify-content-center chart-container "
                            style={{"maxHeight": 357}}>
                            <SurvivorChart chartData={battleData[2]} title="Attacking Troops Remaining" />
                        </div>
                        <div className="col-12 col-md-6 mt-3 mt-md-0 d-flex justify-content-center chart-container"
                            style={{"maxHeight": 357}}>
                            <SurvivorChart chartData={battleData[3]} title="Defending Troops Remaining" />
                        </div>
                    </div>
                </div>
                </>
            }
            </div>
            <OptionsDropdown 
                showOptions={showOptions}
                toggleShow={toggleShowOptions}
                singleRoundState={singleRound} 
                toggleSingleRound={toggleSingleRound}
                isTargetSelectValid={isTargetSelectValid}
                numberTS={numberTS}
                setNumberTS={setNumberTS}
                getMaxTS={getMaxTS}
                targetSelectActive={targetSelectActive}
                handleToggleTargetSelect={handleToggleTargetSelect}
            />
            <div className = {`container-fluid mb-3 justify-content-center bg-dark text-white ${styles.inputBox} ${styles.defaultBox}`}>
                <h4><strong>Select Tech:</strong></h4>
                <div className='d-flex justify-content-around py-2'>
                    <SelectTechInput 
                        techList={landTech}
                        currentTech={currentAttackTech}
                        handleChangeTech={handleChangeTech}
                        side={0}
                    />
                    <SelectTechInput 
                        techList={landTech}
                        currentTech={currentDefenderTech}
                        handleChangeTech={handleChangeTech}
                        side={1}
                    />
                </div>
                <h4><strong>Select Units:</strong></h4>
                <AddTroopsInput 
                    attackAvailable={attackAvailable} 
                    bombardsAvailable={bombardsAvailable} 
                    defenseAvailable={defenseAvailable}
                    handleAddUnit={handleAddUnit}
                    availableAtkCS={null} 
                    availableDefCS = {null}
                />
            </div>
            <div className="container-fluid my-3 px-0 px-sm-2">
                {allowUnitListRender &&
                    <CurrentUnitList 
                        attackUnits={attackUnits} 
                        defenseUnits={defenseUnits}
                        currentAtkTech={currentAttackTech}
                        currentDefTech={currentDefenderTech}
                        techList={techDict}
                        handleRemoveUnit={handleRemoveUnit}
                        handleIncrement={incrementCount}
                        handleDecrement={decrementCount}
                        handleChange={handleChangeCount}
                    />
                }
            </div>
            
            <UtilityBtns handleClear={handleClear} handleSwap={handleSwap} handleShowSave={toggleShowSave} isPopulated={isResultsPopulated}/>

            <div className="container-fluid my-3 text-center">
                <button id="run" className="btn btn-success" style={{"width":"10em", "height": "3em"}} onClick={run}>
                    {isLoading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> :
                    <span style={{"fontSize":22}}>Run</span>}
                </button>
            </div>
            {showSearch &&
                <SearchModal setShow={setShowSearch} runSearch={fetchHistory}/>
            }

            {showSave &&
                <SaveModal setShow={setShowSave} runSave={saveToHistory}/>    
            }
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

        </div>
    );
}

export default LandPage;