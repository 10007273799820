import React from "react";
import classNames from 'classnames'
import Switch from '@mui/material/Switch';

function ToggleSingleRound({currentState, handleClick}){
    return (
        <>
            <Switch
                checked={currentState}
                onChange={handleClick}
                size='large'
            />
            <h6 
            className={classNames({
                'text-success': currentState
            })} 
            style={{"display":"inline-block", "margin":10}}>Single Round of Combat</h6>
        </>
    );
}

export default ToggleSingleRound;