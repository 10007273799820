// src/graphs/WinChart.js
import React from "react";
import { Chart } from 'chart.js/auto';
import { ArcElement, Tooltip, Title, Legend, 
  registerables, LineController } from 'chart.js';
import { Doughnut } from "react-chartjs-2";
Chart.register(...registerables, LineController);
Chart.register(ArcElement, Tooltip, Title, Legend);

function WinChart({ chartData }) {
  const options={
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
        legend: {
            position: 'top'
        },
        title: {
            display: true,
            text: "Win Percentages",
            font: {
              size: 24
            }
        }
    }
  }

  return (
    <Doughnut
      data={chartData}
      options={options}
    />
  );
};

export default WinChart;