import Typewriter from 'typewriter-effect';

export default function TitleWriter({title}) {
    return (
        <h1 className="display-4">
            <Typewriter
                options={{
                    loop: false,
                    cursor: '.'
                }}
                onInit={(tw) => {
                    tw.typeString(title).start()
                }}
            />
        </h1>
    );
}
