import React from 'react';
import { Link, Outlet } from 'react-router-dom';

import { Chip } from '@mui/material';
import SailingIcon from '@mui/icons-material/Sailing'
import FightIcon from '@mui/icons-material/SportsKabaddi'

import '../styles/navbar.css';

import { blueGrey } from '@mui/material/colors';

function NavBar() {
    return (
        <div>
            <nav className="navbar navbar-expand-sm navbar-dark bg-dark">
                <div className="container-fluid ps-3">
                    <h4 className="navbar-brand mb-0">BBR Battle</h4>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav">
                            <li className="nav-item">
                                <Link className="nav-link" aria-current="page" to="/" role = "button">Home</Link>
                            </li>
                            <li className="nav-item dropend">
                                <a className="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false" href='/home'>
                                    Calculators
                                </a>
                                <ul className="dropdown-menu border-0 align-items-start pt-1" style={{backgroundColor: 'rgba(255,255,255,0)'}}>
                                    <li>
                                        <div className='d-flex align-items-start'>
                                            <Link className="dropdend-item ps-2" to="/land">
                                            <Chip
                                                icon={<FightIcon />}
                                                label="Land"
                                                variant="elevated"
                                                className="calculator text-white"
                                            />
                                            </Link>
                                            <Link className="dropdend-item ps-2" to="/sea">
                                            <Chip
                                                icon={<SailingIcon />}
                                                label="Sea"
                                                variant="elevated"
                                                className="calculator text-white"
                                            />
                                            </Link>
                                        </div>
                                    </li>                            
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
            <Outlet />
        </div>
    );
}

export default NavBar;