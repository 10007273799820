import React from "react";
import tuneIcon from "../utility_icons/tune_FILL0_wght400_GRAD0_opsz24.svg";
import ToggleSingleRound from "./ToggleSingleRound";
import { TargetSelect } from "./SelectTargetSelects";
import "../styles/options.css";

import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

//import styles from '../inputBox.module.css';

//<div className={`slide-block ${showHelp ? 'open' : 'closed'}`}



function OptionsDropdown({ showOptions,toggleShow,singleRoundState, toggleSingleRound, 
    isTargetSelectValid, numberTS,  setNumberTS, getMaxTS, targetSelectActive, handleToggleTargetSelect}){
    const tooltip = (
        <Tooltip>
            Settings
        </Tooltip>
    );

    const states = [singleRoundState, targetSelectActive];
    
    return (
        <div className="container-fluid my-3">
            <div className="row">
                <div className="col-md-1 col-2 align-self-center">
                    <OverlayTrigger placement="right" overlay={tooltip} delay={300}>
                        <button className={`btn ${showOptions ? "btn-primary" : "btn-outline-primary"}`} 
                            onClick={toggleShow}>
                                <img src={tuneIcon} alt="toggle settings dropdown"/>
                        </button>
                    </OverlayTrigger>
                </div>
                <div className="col">
                    <div className="d-flex flex-row">
                        {
                            states.map((state, i) => (
                                <div className="flex-fill px-1" key={`option-${i}`}>
                                    <hr key={`divider-${i}`} style={{"border": `3px solid ${ state ? "green" : "black"}`}}/>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
            <div className={`row pt-2 slide-block ${showOptions ? 'open' : 'closed'}`}>
                <div className="col-1"></div>
                <div className="col">
                    <div className="flex-column">
                        <ToggleSingleRound currentState={singleRoundState} handleClick={toggleSingleRound} />
                        <TargetSelect isTargetSelectValid={isTargetSelectValid} numberTS={numberTS}
                        setNumberTS={setNumberTS} getMaxTS={getMaxTS}
                        targetSelectActive={targetSelectActive} handleToggleTargetSelect={handleToggleTargetSelect}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
/*
<div className={`container-fluid my-3 ${currentState ? "border-success" : "border-danger"}`}
style={{"padding": "0.5em"}}>
*/

export default OptionsDropdown;