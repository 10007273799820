import './App.css';
import { Routes, Route } from 'react-router-dom'; 
import LandPage from './pages/LandPage';
import SeaPage from './pages/SeaPage';
import HomePage from './pages/HomePage';
import NavBar from './containers/NavBar';

/*Route between pages*/
function App() {
  //production
  const baseURL = "https://bbr-backend-e092189d2855.herokuapp.com/"
  //style={{backgroundImage: "url(/thumbnails/chess.png)"}}
  return (
    <div className='container-fluid mx-0 px-0'>
      <Routes>
        <Route path="/" element={<NavBar />}>
          
          <Route index element={<HomePage />}/>
          <Route path="land" element={<LandPage baseURL={baseURL}/>}/>
          <Route path="sea" element={<SeaPage baseURL={baseURL}/>}/>
          {/*<Route path="*" element={<NoMatch/>}/>*/}
          
        </Route>
      </Routes>
    </div>
  );
}

export default App;