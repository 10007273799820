import * as React from 'react';
import classNames from 'classnames';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Slider from '@mui/material/Slider';
import { Switch,Input } from '@mui/material';

import TrackIcon from '@mui/icons-material/TrackChanges'

export function TargetSelect({ isTargetSelectValid, getMaxTS, numberTS, setNumberTS, targetSelectActive, handleToggleTargetSelect }) {
    let MAXTS = 1
    if (isTargetSelectValid()){
        MAXTS = getMaxTS()
    }

  const handleSliderChange = (event, newValue) => {
    setNumberTS(newValue)
  }

  const handleInputChange = (event) => {
    let newValue = event.target.value === '' ? 0 : Number(event.target.value)
    setNumberTS(newValue);
  }

  const handleBlur = () => {
    if (numberTS < 0){
        setNumberTS(0);
    } else if (numberTS > MAXTS) {
        setNumberTS(MAXTS);
    }
  }
  return (
    <div className="d-flex flex-row align-items-top">
         <div>
            <Switch
                checked={targetSelectActive}
                onChange={handleToggleTargetSelect}
                size='large'
                disabled={!isTargetSelectValid()}
            />
        </div>
          <div className="flex-fill">
            <div className='row'>
              <div className='col text-start'>
                <h6 
                  className={classNames({
                      'text-success': targetSelectActive
                  })} 
                  style={{"display":"inline-block", "margin":10}}>Enable Target Select
                </h6>
              </div>
              <div className='col-12 col-md-6'>
              {targetSelectActive &&
              <Box sx={{ width: 200}} className="">
                  <Grid container spacing={2} alignItems="center">
                      <Grid item>
                          <TrackIcon sx={{fontSize: 30}}/>
                      </Grid>
                      <Grid item xs>
                          <Slider
                          value={typeof numberTS === 'number' ? numberTS : 0}
                          onChange={handleSliderChange}
                          aria-labelledby="input-slider"
                          step={1}
                          max={MAXTS}
                          />
                      </Grid>
                      <Grid item>
                          <Input
                          value={numberTS}
                          size="small"
                          onChange={handleInputChange}
                          onBlur={handleBlur}
                          inputProps={{
                              step: 1,
                              min: 0,
                              max: MAXTS,
                              type: 'number',
                              'aria-labelledby': 'input-slider',
                          }}
                          />
                      </Grid>
                  </Grid>
              </Box>
              }
              </div>
            </div>
          </div>
     </div>
  );
}