// src/graphs/SurvivorChart.js
import React from "react";
import { Chart } from "chart.js/auto";
import {
    Tooltip,
    Title,
    Legend,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement, 
    BarElement,
    registerables,
    LineController,
    BarController
} from 'chart.js';
import { Bar } from "react-chartjs-2";
Chart.register(...registerables, LineController, BarController);
Chart.register(
    Tooltip,
    Title,
    Legend,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement
);

function SurvivorChart({ chartData,title }) {
    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top'
            },
            title: {
                display: true,
                text: title,
                font: {
                    size: 24
                }
            }
        },
        scales: {
            y: {
                min: 0.0,
                //suggestedMax: 1.0,
                type: 'linear',
                display: true,
                position: 'left'
            },
            y1: {
                min: 0.0,
                type: 'linear',
                display: true,
                position: 'right',
                grid: {
                    drawOnChartArea: false
                }
            }
        },
        interaction: {
            mode: 'index',
            intersect: false
        },
        stacked: false,
    }

  return (
    <Bar
        data={chartData}
        options={options}
    />
  );
};

export default SurvivorChart;