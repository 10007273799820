import Fingerprint from '@mui/icons-material/Fingerprint';
import { TextField } from '@mui/material';
import { Box } from '@mui/material';

import SaveIcon from '@mui/icons-material/SaveAlt';
import TaskAlt from '@mui/icons-material/TaskAlt'
import { Button } from '@mui/material';

import { useState } from 'react';
import IconButton from '@mui/material/IconButton';

import Lottie from 'react-lottie';
import successAnimationData from '../lotties/saveSuccess';
import loadingAnimationData from '../lotties/loading';

import Modal from 'react-bootstrap/Modal';
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from 'react-bootstrap/esm/OverlayTrigger';

export function SaveModal({setShow, runSave}){
    const [searchString, setSearchString] = useState("");
    const [errorMsg, setErrorMsg] = useState("");
    const [showAnimation, setShowAnimation] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [isLoading, setLoading] = useState(false);
    
    const tooltip = (value) => {
        return (
            <Tooltip>
                {value}
            </Tooltip>
        );
    };

    const endAnimation = () => {
        setShowAnimation(false);
        setShowSuccess(true);
    }

    const handleRunSave = async () => {
        setLoading(true);
        const result = await runSave(searchString);
        setLoading(false);
        if (result.success){
          setShowAnimation(true);
        } else {
          setErrorMsg(result.error);
        }
    }

    const handleChange = (value) => {
        setSearchString(value);
    }
  
    const handleClose = () => setShow(false);
  
      
    const successOptions = {
        loop: false,
        autoplay: true,
        animationData: successAnimationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    const loadingOptions = {
        loop: true,
        autoplay: true,
        animationData: loadingAnimationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    return (
      <Modal show={true} onHide={handleClose} width="400" height="400">
        <Modal.Header closeButton>
          <Modal.Title>Save To History</Modal.Title>
        </Modal.Header>
        { 
            (showSuccess)
        ?
            <Modal.Body>
                <Button onClick={handleClose} color="success" variant="contained" startIcon={<TaskAlt />}>
                    Battle ID: "<strong>{searchString}</strong>" successfully saved.
                </Button>
            </Modal.Body>
        :
            (isLoading)
            ?
            <Modal.Body>
                <div>
                    <Lottie 
                        options={loadingOptions}
                        height={300}
                        width={300}
                    />
                </div>
            </Modal.Body>
            :

            (showAnimation)
            ? 
            <Modal.Body>
                <div>
                    <Lottie 
                    options={successOptions}
                    height={300}
                    width={300}
                    eventListeners={[
                        {
                        eventName: 'complete',
                        callback: () => endAnimation()
                        }
                    ]}
                    />
                </div>
            </Modal.Body>
            :
            <>
                <Modal.Body>
                Save this battle by a unique identifier (UID)
                <br />
                <Box className="mt-2" sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    <Fingerprint sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                    <TextField 
                    id="input-with-sx" 
                    label="Save Battle with ID..." 
                    variant="standard" 
                    value={searchString}
                    onChange={e => handleChange(e.target.value)}
                    />
                </Box>

                {errorMsg !== "" && 
                    <p className='text-danger'>{errorMsg}</p>
                }
                </Modal.Body>
                <Modal.Footer>
                    <OverlayTrigger placement="top" overlay={tooltip("Save")} delay={200}>
                        <IconButton onClick={handleRunSave} color="success" aria-label="Save">
                            <SaveIcon sx={{fontSize: 30}}/>
                        </IconButton>
                    </OverlayTrigger>
                </Modal.Footer>
            </>
        }
      </Modal>
    );
}

  