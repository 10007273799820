import React from 'react';

//Land Tech
import advArt from '../techTokens/high_res_pngs/advancedArtillery-gigapixel-low_res-scale-6_00x.png';
import atc from '../techTokens/high_res_pngs/radarATC-gigapixel-low_res-scale-6_00x.png';
import heavyBomb from '../techTokens/high_res_pngs/heavyBombers-gigapixel-low_res-scale-6_00x.png';
import heavyTank from '../techTokens/high_res_pngs/heavyTanks-gigapixel-low_res-scale-6_00x.png';
import jets from '../techTokens/high_res_pngs/jetFighters-gigapixel-low_res-scale-6_00x.png';
import superBB from '../techTokens/high_res_pngs/superBattleships-gigapixel-low_res-scale-6_00x.png';
import noTech from '../techTokens/svgs/noTech.svg';

//Sea Tech
import supSubs from '../techTokens/high_res_pngs/superSubmarines-gigapixel-low_res-scale-6_00x.png';
import supACC from '../techTokens/high_res_pngs/superCarriers-gigapixel-low_res-scale-6_00x.png';
import impTrans from '../techTokens/high_res_pngs/improvedTransports-gigapixel-low_res-scale-6_00x.png';

import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';

const techTokens = {
    "Advanced Artillery": advArt,
    "ATC/Radar": atc,
    "Heavy Bombers": heavyBomb,
    "Heavy Tanks": heavyTank,
    "Jet Fighters": jets,
    "Super Battleships": superBB,
    "No Tech": noTech,
    "Super Subs": supSubs,
    "Super Carriers": supACC,
    "Improved Transports": impTrans
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 100
    },
  },
};

export default function SelectTechInput({ techList,currentTech,handleChangeTech, side }) {
  const theme = useTheme();
  let activeTech;
    for (const key in techList) {
        if (techList[key] === currentTech) {
            activeTech = key;
            break;
        }
    }
  return (
    <div>
      <FormControl sx={{ m: 1, color: '#fff', width: "40vw", maxWidth: 300}} fullWidth>
        <InputLabel id={`demo-multiple-chip-label${side}`} sx={{color: '#fff'}}>{side === 0 ? "Attacker" : "Defender"} Tech</InputLabel>
        <Select
          labelId={`demo-multiple-chip-label${side}`}
          id={`demo-multiple-chip${side}`}
          input={<OutlinedInput id={`select-multiple-chip${side}`} label="Chip" />}
          value={"Occupied"}
          renderValue={() => (
                <Box sx={{ display: 'block', gap: 0.5 }}>
                    <Chip
                        avatar={<Avatar alt="Tech Token" src={techTokens[activeTech]} />}
                        label={activeTech}
                        variant={currentTech === "radioBtnX" ? "filled" : "outlined"}
                        sx={{color: '#fff'}}
                        color={currentTech === 'radioBtnX' ? 'error' : 'default'}
                    />
                </Box>
            )}
          MenuProps={MenuProps}
        >
          {Object.entries(techList).map(([value, code]) => (
            currentTech !== code &&
                <MenuItem
                    key={`${value}${side}`}
                    onClick={() => handleChangeTech(side, code)}
                >
                    {value}
                </MenuItem>     
          ))}
        </Select>
      </FormControl>
    </div>
  );
}