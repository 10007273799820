import React from 'react';
import { Accordion } from '@mui/material';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TipsIcon from '@mui/icons-material/TipsAndUpdates';
import AssignmentIcon from '@mui/icons-material/Assignment'
import { Avatar } from '@mui/material';
import SupportIcon from '@mui/icons-material/Support'
import SparkleIcon from '@mui/icons-material/Insights'
import TrackIcon from '@mui/icons-material/TrackChanges'
import tuneIcon from "../utility_icons/tune_FILL0_wght400_GRAD0_opsz24.svg";

import HistoryIcon from '@mui/icons-material/History';
import SaveIcon from '@mui/icons-material/SaveAlt';

export function RecentUpdates(){
    return (
        <>
            <div className='px-3'>
                <h3>
                    News
                </h3>
            </div>
            <Accordion>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                >
                    <Typography><TipsIcon color='warning'/>&nbsp;&nbsp;&nbsp;Save and Share</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        You can now avoid repitition and share battles with your partner by saving
                       it with a unique identifier. Look for the <SaveIcon color='primary'/> icon 
                        to save and the <HistoryIcon color='primary'/> icon to recall previously saved instances.
                    </Typography>
                </AccordionDetails>
            </Accordion>

            <Accordion>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                >
                    <Typography><TrackIcon color='success'/>&nbsp;&nbsp;&nbsp;Control Of Target Selects</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        You can now turn on and off target selects as well as choose the number of
                        tacticals you would like to use their target select shots. These shots are assigned one to
                        each available target ranked by priority. If there are more target selects used than targetable defenders,
                        these excess shots are assigned to "double up on existing targets", again in order of priority.
                        <br/><br/>
                        For land battles priority rank order is:
                        <ol>
                            <li>Tank</li>
                            <li>Artillery</li>
                            <li>Mechanized Infantry</li>
                            <li>Cavalry</li>
                        </ol>
                        For sea battles the priority rank order is:
                        <ol>
                            <li>Cruiser</li>
                            <li>Damaged (Super) Battleship</li>
                            <li>Destroyer</li>
                            <li>Super ACC</li>
                            <li>ACC</li>
                            <li>Damaged ACC</li>
                        </ol>
                        <br/>
                        This is available in the options menu which can be opened by clicking this &nbsp;
                        <img src={tuneIcon} alt="toggle settings dropdown"/> &nbsp;icon in any battle calculator.
                    </Typography>
                </AccordionDetails>
            </Accordion>

            <Accordion>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                >
                    <Typography><SparkleIcon color='primary'/>&nbsp;&nbsp;&nbsp;Beautification</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        Improved accessibility for mobile and took the rust off of some stuff I 
                        had hacked together originally.
                    </Typography>
                </AccordionDetails>
            </Accordion>

            <Accordion>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                >
                    <Typography><AssignmentIcon color='secondary'/>&nbsp;&nbsp;&nbsp;Soon...</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    
                    <dl>
                        <div className='d-flex align-items-center'>
                            <dt><Avatar
                                    alt="Paratrooper"
                                    src="/thumbnails/paratrooper.png"
                                    sx={{width:60, height: 60}}
                            /></dt>
                            <dd><span style={{fontSize: 24}}>&nbsp;&nbsp;&nbsp;Air Transports</span></dd>
                        </div>  
                        <div className='d-flex align-items-center my-2'>  
                            <dt><SupportIcon color="error" sx={{fontSize: 60}}/></dt>
                            <dd><span style={{fontSize: 24}}>&nbsp;&nbsp;&nbsp;Save Aircraft Carriers</span></dd>
                        </div>
                            
                        <div className='d-flex align-items-center'>  
                            <dt><Avatar
                                    alt="Big Board with People"
                                    src="/thumbnails/big_board.png"
                                    sx={{width:60, height: 60}}
                            /></dt>
                            <dd><span style={{fontSize: 24}}>&nbsp;&nbsp;&nbsp;YouTube Copilot</span></dd>
                        </div>
                            
                    </dl>
                    
                </AccordionDetails>
            </Accordion>
        </>
    )
}