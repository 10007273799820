import React from 'react';
import styles from '../inputBox.module.css';

import { IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import { Input } from '@mui/material';

import die0 from '../dice/0-square.svg';
import die1 from '../dice/dice-1.svg';
import die2 from '../dice/dice-2.svg';
import die3 from '../dice/dice-3.svg';
import die4 from '../dice/dice-4.svg';
import die5 from '../dice/dice-5.svg';
import die6 from '../dice/dice-6.svg';

import inf from '../img/troops/inf.svg';
import art from '../img/troops/art.svg';
import cav from '../img/troops/cav.svg';
import truck from '../img/troops/truck.svg';
import tank from '../img/troops/tank.svg';

import fig from '../img/troops/fig.svg';
import tac from '../img/troops/tac.svg';
import strat from '../img/troops/str.svg';

import aaa from '../img/troops/aaa.svg';
import bombard from '../img/troops/bb.svg';

//Ship SVGs
import sub from '../img/ships/sub.svg';
import transport from '../img/ships/transport.svg';
import battleship from '../img/ships/battleship.svg';
import cruiser from '../img/ships/cruiser.svg';
import acc from '../img/ships/acc.svg';
import destroyer from '../img/ships/destroyer.svg';

import fullHeart from '../utility_icons/heart-fill.svg';
import brokeHeart from '../utility_icons/heartbreak-fill.svg';

const unitSVGs = {
    "Infantry" : inf,
    "Artillery" : art,
    "Cavalry" : cav,
    "Mechanized Infantry" : truck,
    "Tank" : tank,
    "Fighter" : fig,
    "Tactical Bomber": tac,
    "Strategic Bomber": strat,
    "AAA" : aaa,
    "Battleship Bombard" : bombard,
    "Cruiser Bombard" : bombard,
    "Submarine": sub,
    "Transport": transport,
    "Destroyer": destroyer,
    "Cruiser": cruiser,
    "ACC": acc,
    "Battleship": battleship,
}

const svg_alias = {
    "Damaged Battleship": "Battleship",
    "Super Battleship": "Battleship",
    "Hurt Super Battleship": "Battleship",
    "Damaged Super Battleship": "Battleship",
    "Damaged ACC": "ACC",
    "Super ACC": "ACC",
    "Hurt Super ACC": "ACC",
    "Damaged Super ACC": "ACC"
}

const name_alias = {
    "Mechanized Infantry": "Mech. Infantry",
    "Strategic Bomber": "Strat. Bomber",
    "Tactical Bomber" : "Tac. Bomber",
    "Battleship Bombard": "BB",
    "Cruiser Bombard": "CB",
    "Damaged Super ACC": "[2D] S.ACC",
    "Hurt Super ACC": "[1D] S.ACC",
    "Damaged ACC": "[1D] ACC",
    "Damaged Battleship": "[1D] Battleship",
    "Hurt Super Battleship": "[1D] S.Battleship",
    "Damaged Super Battleship": "[2D] S.Battleship",
    "Submarine": "Sub"
}


const dice = {
    0 : die0,
    1 : die1,
    2 : die2,
    3 : die3,
    4 : die4,
    5 : die5,
    6 : die6
}

const hp_img = {
    'f': fullHeart,
    'x': brokeHeart
}



function CurrentUnitList({ attackUnits,defenseUnits,currentAtkTech,currentDefTech,techList,handleRemoveUnit,handleIncrement,handleDecrement,handleChange }){
    return (
        <>
            <div className="row">
                <UnitList units={attackUnits} 
                    handleRemoveUnit={handleRemoveUnit} 
                    handleIncrement={handleIncrement} 
                    handleDecrement={handleDecrement} 
                    side={0}
                    currentTech={currentAtkTech}
                    techList={techList}
                    handleChange={handleChange}
                />
                <UnitList units={defenseUnits} 
                    handleRemoveUnit={handleRemoveUnit} 
                    handleIncrement={handleIncrement}
                    handleDecrement={handleDecrement}
                    side={1}
                    currentTech={currentDefTech}
                    techList={techList}
                    handleChange={handleChange}
                />
            </div>
        </> 
    );
}


function UnitList({ units,side,currentTech,techList,handleRemoveUnit,handleIncrement,handleDecrement,handleChange }) {

    const checkIsSuper = (name) => {
        let isSuper;
        if ("capitalTechs" in techList && currentTech in techList["capitalTechs"]) {
            isSuper = (name in techList["capitalTechs"][currentTech]) ? 1 : 0;
        } else {
            isSuper = (techList[currentTech]["efx"] === name) ? 1 : 0;
        }
        return isSuper;
    }

    const superDict = () => {
        if ("capitalTechs" in techList && currentTech in techList["capitalTechs"]) {
            return null;
        } else {
            return techList[currentTech];
        } 
    }

    if (units.length === 0) {
        return <div className="col-6"></div>;
    } else {
        return (
            <div className="col-6">
                <ul className="list-group">
                    {
                        units.map((unit, idx) => (
                            <UnitListItem 
                                unit={unit}
                                key={idx} 
                                handleRemoveUnit={handleRemoveUnit}
                                handleIncrement={handleIncrement}
                                handleDecrement={handleDecrement}
                                side={side}
                                index={idx}
                                isSuper={checkIsSuper(unit.name)}
                                superDict={superDict()}
                                handleChange={handleChange}
                            />
                        ))
                    }
                </ul>
            </div>
        );
    }
}

function UnitListItem({ unit,handleRemoveUnit,handleIncrement,handleDecrement,side,index,isSuper,superDict, handleChange }) {
    return (
        <li className={`mt-2 p-0 p-sm-1 ${isSuper === 1 ? `${styles.activated} text-dark list-group-item` : "list-group-item"}`}>
            <div className="container-fluid px-0 px-sm-2 py-1">
                <div className="row align-items-center" style={{margin: 'auto'}}>
                    <div className="col-4 col-md-2 col-lg-1 ps-md-0" align="center">
                        <IconButton size="small" color="error" aria-label='decrement one' onClick={() => handleDecrement(side, index)}>
                            <RemoveIcon />
                        </IconButton>
                    </div>
                    <div className="col-4 col-md-1 col-lg-2 ps-md-1 ps-lg-3 text-center">
                        <Input 
                            type='number' 
                            variant='outlined'
                            value={unit.count !== "" ? unit.count : 0}
                            onChange={e => handleChange(e.target.value, side, index)}
                            min="0"
                        />
                    </div>
                    <div className="col-4 col-md-2 col-lg-1 ps-lg-1 justify-content-center px-0">
                        <IconButton size="small" color="success" aria-label='Increment one' onClick={() => handleIncrement(side, index)}>
                            <AddIcon />
                        </IconButton>
                    </div>
                    <div className="col-9 col-md-3 col-lg-4 ps-md-3 ps-lg-4 ml-md-1 text-center pt-2 pt-md-1">
                        <div className="row">
                            <div className="col-3 col-lg-6">
                                <div className='d-flex justify-content-between'>
                                    <div>
                                        {unit.name in unitSVGs ? 
                                            <img src={unitSVGs[unit.name]} height={30} alt="Unit Profile" />
                                        :
                                            <img src={unitSVGs[svg_alias[unit.name]]} height={30} alt="Unit Profile" />
                                        }
                                    </div>
                                    {"hp" in unit && (
                                        <div className='d-none d-sm-block'>
                                            <div className="d-flex">
                                                {unit["hp"].map( (heart, idx) => (
                                                    <div key={`heart${idx}${unit.name}`}>
                                                        <img key="heard-img-${idx}" src={hp_img[heart]} height={7} alt="Life Point" />
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                     )}
                                </div>
                            </div>
                            <div className="col-9 col-lg-6 text-end">
                                <h6><strong>
                                    {unit.name in name_alias ? name_alias[unit.name] : unit.name}
                                </strong></h6>
                            </div>
                        </div>
                    </div>
                    <div className="col-4 col-md-2 ps-md-3 ps-lg-5 text-center pb-4 pb-md-0 pe-md-0 d-none d-md-block">
                        <div className='row'>
                            <DiceList unit={unit} isSuper={isSuper} side={side} superDict={superDict}/>
                        </div>
                    </div>
                    <div className="col-md-2 col-3 px-0 pt-2 pt-md-0"  align="right">
                        <IconButton onClick={() => handleRemoveUnit(index, side, unit.name)} color="error">
                            <DeleteIcon/>
                        </IconButton>
                    </div>
                </div>
            </div>
        </li>
    );
}

function DiceList({ unit,isSuper,side,superDict }) {
    if (isSuper === 1 && superDict !== null) {
        return (
            <>
                {Array.isArray(superDict[side]) ? superDict[side].map((roll, i) => (
                    <div className='col-6'>
                        <img key={i} src={dice[roll]} height={20} alt="Dice Value" />
                    </div> 
                )) 
                : 
                    <div>
                        <img src={dice[superDict[side]]} height={20} alt="Dice Value" />
                    </div>
                }
            </>
        );
    } else { 
        return (
            <>
                {Array.isArray(unit.val) ? unit.val.map((roll, i) => 
                    (
                        <div className='col-6'>
                            <img key={i} src={dice[roll]} height={20} alt="Dice Value" />
                        </div>
                    )) 
                    : 
                        <img src={dice[unit.val]} height={20} alt="Dice Value" />
                }
            </>
        );
    }
}

export default CurrentUnitList;