import React from 'react';

function AddTroopsInput({ attackAvailable,bombardsAvailable,defenseAvailable,style,handleAddUnit, availableAtkCS, availableDefCS  }) {
    return (
        <>
            <div className="container-fluid text-center">
                <div className="row justify-content-center mt-2">
                    <div className="col-6" align='left'><h5>Attackers:</h5></div>
                    <div className="col-6" align='left'><h5>Defenders:</h5></div>
                </div>
                <div className="row justify-content-center">
                    <div className="dropdown-center d-grid col-6">
                        <AddUnitDropdown troops={attackAvailable} bombards={bombardsAvailable} capitalShips = {availableAtkCS}
                        handleAddUnit={handleAddUnit} side={0}/>
                    </div>
                    <div className="dropdown-center d-grid col-6">
                        <AddUnitDropdown troops={defenseAvailable} bombards={null} capitalShips = {availableDefCS}
                        handleAddUnit={handleAddUnit} side={1}/>
                    </div>
                </div>
            </div>
        </>
    );
}

function AddUnitDropdown({ troops,bombards,handleAddUnit,side,capitalShips }) {
    return (
        <>
            <button className="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                Add Units
            </button>
            <ul className="dropdown-menu dropdown-menu-dark text-light" data-bs-theme="dark">
                {capitalShips !== null && (
                    <>
                        <h6 className="dropdown-header">Aircraft Carriers:</h6>
                        <ul>
                            {capitalShips[0].map((capShip, idx) => (
                                <li key={`ACC${idx}`}><a className="dropdown-item" onClick={handleAddUnit} data-side={side} href="#run">{capShip}</a></li>
                            ))}
                        </ul>
                        <h6 className="dropdown-header">Battleships:</h6>
                        <ul>
                            {capitalShips[1].map((capShip, idx) => (
                                <li key={`BS${idx}`}><a className="dropdown-item" onClick={handleAddUnit} data-side={side} href="#run">{capShip}</a></li>
                            ))}
                        </ul>
                    </>
                )}
                {
                    troops.sort().map((troop, idx) => (
                            <li key={idx}><a className="dropdown-item" onClick={handleAddUnit} data-side={side} href="#run">{troop}</a></li>
                    ))
                }
                {   bombards !== null && <li><hr className="dropdown-divider"/></li> }
                {   bombards !== null && bombards.map(ship => <li key={ship}><a className="dropdown-item" onClick={handleAddUnit} data-side={side} href="#run">{ship}</a></li>) }
            </ul>
        </>
    );
}

export default AddTroopsInput;