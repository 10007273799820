// src/graphs/IpcChart.js
import React from "react";
import { Chart } from "chart.js/auto";
import {
    Tooltip,
    Title,
    Legend,
    CategoryScale,
    LinearScale, 
    BarElement,
    registerables,
    LineController,
    BarController
} from 'chart.js';
import { Bar } from "react-chartjs-2";
Chart.register(...registerables, LineController, BarController);
Chart.register(
    Tooltip,
    Title,
    Legend,
    CategoryScale,
    LinearScale,
    BarElement
);

function IpcChart({ chartData }) {
    const options = {
        maintainAspectRatio: true,
        indexAxis: 'y',
        responsive: true,
        plugins: {
            legend: {
                position: 'top'
            },
            title: {
                display: true,
                text: "Average IPC Loss",
                font: {
                    size: 24
                }
            }
        },
    };

  return (
    <Bar
      data={chartData}
      options={options}
    />
  );
};

export default IpcChart;